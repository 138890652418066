import './scss/main.scss'

const screenHeight = () => {
    var vh = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const mq = window.matchMedia('(max-width: 840px)');

function init() {
    gridBorders();
}

window.onload = (event) => {
    init();
    screenHeight();
};

window.addEventListener('resize', () => {
    screenHeight();
    gridBorders();
});

function gridBorders() {
    const gridContainer = document.querySelector(".lines_container");
    gridContainer.innerHTML = "";

    const grid = document.querySelector('.grid-container');
    let rows = window.getComputedStyle(grid).getPropertyValue('grid-template-rows').split(" ");
    let columns = window.getComputedStyle(grid).getPropertyValue('grid-template-columns').split(" ");

    let total_top = 0;
    let total_left = 0;

    for (let i = 1; i < rows.length; i++) {
        const row_height = Number.parseInt(rows[i-1]);
        total_top += row_height;

        const gridLine = document.createElement('div');
        gridLine.className = "grid-line";
        gridLine.style.position = "absolute";
        gridLine.style.width = "100%";
        gridLine.style.top = total_top + "px";
        // gridLine.style.borderTop = "1px solid var(--troupe-beige)";

        gridContainer.appendChild(gridLine);
    }

    for (let i = 1; i < columns.length; i++) {
        const column_width = Number.parseInt(columns[i-1]);
        total_left += column_width;

        const gridLine = document.createElement('div');
        gridLine.className = "grid-line";
        gridLine.style.position = "absolute";
        gridLine.style.height = Math.max(window.innerHeight, document.body.scrollHeight) + 'px';
        gridLine.style.left = total_left + "px";
        // gridLine.style.borderLeft = "1px solid var(--troupe-beige)";

        gridContainer.appendChild(gridLine);
    }
}
